import React from 'react';
import MetaTags from 'react-meta-tags';

import PageHeader from './PageHeader';
import PageFooter from './PageFooter';
import PageBody from './PageBody';
import SidebarComponent from './SidebarComponent'

class BusinessCardPage extends React.Component {
    renderPageBodyContent = () => {
        return (
            <React.Fragment>
                <div className="mainBlock mainBlockGrey text-center">
                    <h1 className="text-left">Feng&#x00027;s Business Card</h1>
                    <p className="text-left"><b>A referral from you is the highest compliment I can receive!</b> If you like my services, please refer me to your friends and family members
                        who need to buy or sell homes.</p>
                    <p className="text-left">Copy Feng's business card url below to start sharing!</p>
                    <a href="/business-card.png" data-lightbox="fcai-business-card" data-title="Need to sell your house or buy a new house in Memphis? Feng can help!">
                        <img src="/business-card.png" className="businessCard"/>
                    </a>
                    <br/>
                    <div className="copyWidgets">
                        <p id="visibleBizCardUrl">https://feng.properties/business-card.png</p>
                        <button className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent businessCardCopyBtn" data-clipboard-target="#visibleBizCardUrl">
                            Copy to clipboard
                        </button>
                    </div>
                    <div className="myToolTip" style={{display: 'none' }}>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    render() {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>Feng Business Card</title>
                    <meta name="description" content="Real estate Agent Feng&amp;#x27;s Business Card" />
                    <link rel="cannonical" href="/business-card" />
                </MetaTags>
                <PageHeader />
                <div className="container-fluid bodyWrapper">
                    <div className="row reorder-xs">
                        <div className="col-xs-12 col-md-8">
                            <PageBody renderPageBodyContent={this.renderPageBodyContent} />
                        </div>
                        <div className="col-xs-12 col-md-4">
                            <SidebarComponent></SidebarComponent>
                        </div>
                    </div>
                </div>
                <PageFooter />
            </React.Fragment>
        );
    }
}
export default BusinessCardPage;