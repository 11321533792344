import React from 'react';

class PageBody extends React.Component {

    render() {
        return (
            <div className="pageContentMaxWidth">
                {this.props.renderPageBodyContent()}
            </div>
        );
    }
}

export default PageBody;