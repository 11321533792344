import React from 'react';
import GoogleMapReact from 'google-map-react';
import OfficeMapContent from './OfficeMapContent'

class OfficeMapComponent extends React.Component {
    constructor(props) {
        super(props);
        //this.GOOGLE_MAP_URL = '//maps.googleapis.com/maps/api/js?key=AIzaSyDdUdXrzndz8jFAUS3xyLya5_AVcX1PPm0';
        this.OFFICE_LOCATION = {
            lat: 35.149971,
            lng: -89.793785
        };
        this.defaultZoom = 15;
    }

    renderMarkers = (map, maps) => {
         let marker = new maps.Marker({
            position: { lat: this.OFFICE_LOCATION.lat, lng: this.OFFICE_LOCATION.lng },
            map,
         });
         return marker;
    };

    render() {
        return (
            <React.Fragment>
                <h3>Office Location</h3>
                <ul className="list-unstyled">
                    <li>890 Willow Tree Circle, Suite 6</li>
                    <li>Cordova, TN 38018</li>
                </ul>
                <div id="map" >
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyDdUdXrzndz8jFAUS3xyLya5_AVcX1PPm0" }}
                    defaultCenter={this.OFFICE_LOCATION}
                    defaultZoom={this.defaultZoom}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
                    >
                    <OfficeMapContent lat={this.OFFICE_LOCATION.lat} lng={this.OFFICE_LOCATION.lng}></OfficeMapContent>
                </GoogleMapReact>
                </div>
            </React.Fragment>
        );
    }
}

export default OfficeMapComponent;