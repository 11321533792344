import React from 'react';
import MetaTags from 'react-meta-tags';

import PageHeader from './PageHeader';
import PageFooter from './PageFooter';
import PageBody from './PageBody';
import SidebarComponent from './SidebarComponent'

class NotFoundPage extends React.Component {
    renderPageBodyContent = () => {
        return (
            <div class="mainBlock mainBlockGrey">
                <h2>404 Not Found!</h2>
                <p>The page you are looking for does not exist.</p>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>404 Not Found</title>
                    <meta name="description" content="404 Page not found." />
                    <link href="/not-found" rel="cannonical" />
                    <meta name="robots" content="noindex, nofollow" />
                </MetaTags>
                <PageHeader />
                <div className="container-fluid bodyWrapper">
                    <div className="row reorder-xs">
                        <div className="col-xs-12 col-md-8">
                            <PageBody renderPageBodyContent={this.renderPageBodyContent} />
                        </div>
                        <div className="col-xs-12 col-md-4">
                            <SidebarComponent></SidebarComponent>
                        </div>
                    </div>
                </div>
                <PageFooter />
            </React.Fragment>
        );
    }
}
export default NotFoundPage;