import React from 'react';
import {connect} from 'react-redux';
import PROD_CONTACT_API from '../apis/api';

class SidebarForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                formName: 'Contact Feng Form',
                fullName: '',
                phone: '',
                email: '',
                questions: ''
            },
            submitErrors: [],
            submitted: false,
            submitting: false
        }
    }
    submitBtnRef = React.createRef();

    handleSubmit = (e) =>
    {
        e.preventDefault();
        this.setState({submitting:true});
        this.submitBtnRef.current.disabled = true;
        //console.log('form submitted ' + JSON.stringify(this.state));
        PROD_CONTACT_API.post('/api/leads/v1', this.state.form)
        .then(res => {
            //console.log(res);
            if(res.status = 200) {
                this.setState({submitted: true});
            } else {
                if(res.data.errors && res.data.errors.length > 0) {
                    this.setState({submitErrors: res.errors});
                } 
            }
        }).catch( (e) => {
            console.log(e);
            this.setState({submitErrors: ['Unable to submit. Please try again later.']});
            }
        ).then(() => { 
            this.setState({submitting:false});
            if(this.submitBtnRef && this.submitBtnRef.current) {
                this.submitBtnRef.current.disabled = false;
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className={`panel panel-default sidebarBlock ${this.props.hideSidebarForm ? 'hideSidebar' : ''}`}>
                    <div className="panel-heading">Contact Feng</div>
                    <div className="panel-body">
                        {!this.state.submitted ? 
                        <div>
                            <p id="contactFormHeader">If you have any questions, please don't hesitate to fill out the following form. I will get back to you within 24 hours.</p>
                            <form method="post" name="contactForm" id="contactForm" className="forms" onSubmit={this.handleSubmit}>
                                <div className="form-group">
                                    <input type="text" className="form-control" id="fullName" name="fullName" maxLength="128" placeholder="Name" onChange={(e) => this.state.form.fullName=e.target.value} required />
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" id="phone" name="phone" maxLength="10" pattern="[0-9]{3}[0-9]{3}[0-9]{4}" placeholder="Phone (xxxxxxxxxx)" onChange={(e) => this.state.form.phone=e.target.value} required />
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" id="email" name="email" maxLength="255" pattern="\S+@\S+\.\S+" placeholder="Email" onChange={(e) => this.state.form.email=e.target.value} required />
                                </div>
                                <div className="form-group">
                                    <textarea className="form-control" rows="5" name="questions" id="questions" maxLength="1024" placeholder="Questions" onChange={(e) => this.state.form.questions=e.target.value} required></textarea>
                                </div>
                                <div className="text-center">
                                    <button type="submit" ref={this.submitBtnRef} id="contactFormBtn" className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent" data-upgraded=",MaterialButton,MaterialRipple">
                                        Submit
                                        <span className="mdl-button__ripple-container"><span className="mdl-ripple"></span></span>
                                    </button>
                                    <div id="progressBar" className={`mdl-progress mdl-js-progress mdl-progress__indeterminate ${this.state.submitting ? '' : 'hide'}`}></div>
                                </div>

                                {
                                    this.state.submitErrors && this.state.submitErrors.length ?
                                    <div id="errorMessage">
                                        <h3>Submit failed. Errors:</h3>
                                        <ul>
                                            {this.state.submitErrors.map(e => (<li>{e}</li>))} 
                                        </ul>
                                    </div>
                                    :
                                    <React.Fragment></React.Fragment>
                                }
                            </form>
                        </div>
                        :
                        <div>
                            Thank you! Your form has been submitted. I will get back with you ASAP.
                        </div>}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
}
export default connect(mapStateToProps)(SidebarForm);