import React from 'react';
import { NavLink} from "react-router-dom";
import SidebarForm from './SidebarForm';

class SidebarComponent extends React.Component {
    MAINTENANCE_TIPS = [
        'Once a year, vacuum the refrigerator coils and underneath the appliance.',
        'Have the fireplace chimney inspected and cleaned annually.',
        'Remember to change out HVAC filters when it is dirty.',
        'Replace the batteries in smoke and CO detectors annually.',
        "Vacuum the cloths dryer's exhaust duct at least once a year.",
        'Inspect window and door caulking and weather stripping yearly.',
        'Replace the batteries in smoke and CO detectors annually.',
        "Clean leaves and debris from the condenser of a central air conditioner seasonally."
    ];
    maintenanceTip = this.MAINTENANCE_TIPS[Math.floor(Math.random() * this.MAINTENANCE_TIPS.length)];
    
    render() {
        return (
            <React.Fragment>
                <div className="panel panel-default sidebarBlock" id="maintenanceTips">
                    <div className="panel-heading"><i className="fa fa-info-circle" aria-hidden="true">&nbsp;</i>House Maintenance Tips</div>
                    <div className="panel-body">{this.maintenanceTip}</div>
                </div>
                <div className="panel panel-default sidebarBlock" id="sidebarPhoto" >
                    <NavLink className="nav-link" exact to="/"><img src="/img/feng/fengcai.jpg" /></NavLink>
                </div>
                <SidebarForm hideSidebarForm={this.props.hideSidebarForm}></SidebarForm>
            </React.Fragment>
        );
    }
}

export default SidebarComponent;