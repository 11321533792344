import React from 'react';
import { NavLink} from "react-router-dom";

class PageFooter extends React.Component {
    render() {
        const today = new Date();
        return (
            <React.Fragment>
                <div className="footerTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-5">
                                <ul className="footer-contact">
                                    <li>
                                        <a href="http://www.adarorealty.com" target="_blank"><img src="/img/adaro-logo.jpg" className="firmLogo"/></a></li>
                                    <li>Adaro Realty</li>
                                    <li>890 Willow Tree Circle, Suite 6</li>
                                    <li>Cordova, TN 38018</li>
                                    <li>901-751-6675</li>
                                </ul>
                            </div>
                            <div className="col-sm-xs col-sm-7 footerLogos">
                                <img src="/img/equal_housing_logo.gif" className="equalHousingLogo"/>
                                <img src="/img/mls_logo.jpg" className="mlsLogo"/>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="footer">
                    <div className="text-center">
                        <p className="text-muted">feng.properties |
                            &copy; 2019 {(today.getFullYear() > 2019 ? '- '+today.getFullYear() : '')}, All Rights Reserved.</p>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}

export default PageFooter;