import React from 'react';
import {
    BrowserRouter,
    Route,
    Switch,
  } from 'react-router-dom';
  import { createBrowserHistory } from 'history';
  
import HomePage from './HomePage';
import BuyPage from './BuyPage';
import SellPage from './SellPage';
import RentalPrescreeningPage from './RentalPrescreeningPage';
import NotFoundPage from './NotFoundPage';
import BusinessCardPage from './BusinessCardPage';
import SimpleReactLightbox from 'simple-react-lightbox';
import ReactGA from "react-ga4";

ReactGA.initialize('G-V01DVBPYX1');

const history = createBrowserHistory();
// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.send({hitType: "pageView", page: location.pathname}); // Record a pageview for the given page
});

class App extends React.Component {
    render() {
        return (
            <SimpleReactLightbox>
                <BrowserRouter history={history}>
                    <Switch>
                        <Route path="/" exact component={HomePage} />
                        <Route path="/buy" exact component={BuyPage} />
                        <Route path="/sell" exact component={SellPage} />
                        <Route path="/business-card" exact component={BusinessCardPage} />
                        <Route path="/rental-prescreening" exact component={RentalPrescreeningPage} />
                        <Route path="*" component={NotFoundPage} status={404}/>
                    </Switch>
                </BrowserRouter>
            </SimpleReactLightbox>
        );
    }
}

export default App;