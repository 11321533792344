import React from 'react';
import MetaTags from 'react-meta-tags';

import PageHeader from './PageHeader';
import PageFooter from './PageFooter';
import PageBody from './PageBody';
import SidebarComponent from './SidebarComponent'
import PROD_CONTACT_API from '../apis/api';

class RentalPrescreeningPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                formName: 'Rental Prescreening Form',
                fullNameLeft: '',
                phoneLeft: '',
                emailLeft: '',
                currentResidency: '',
                currentResidencyDuration: '',
                moveOutCurrentResidencyReason: '',
                wasEvicted: '',
                creditScore: '',
                monthlyIncome: '',
                howLongWithCurrentEmployer: '',
                readyForMoveIn: '',
                landlordEmployerHistory: '',
                propertyAddr: '',
                howManyPeople: '',
                plannedMoveInDate: '',
                stayForHowLong: '',
                doYouSmoke: '',
                doYouHavePets: '',
                howDidYouHearOurRental: ''
            },
            submitErrors: [],
            submitted: false,
            submitting: false
        };
    }
    submitBtnRef = React.createRef();

    handleSubmit = (e) =>
    {
        e.preventDefault();
        this.setState({submitting:true});
        this.submitBtnRef.current.disabled = true;
        //console.log('form submitted ' + JSON.stringify(this.state));
        PROD_CONTACT_API.post('/api/leads/v1', this.state.form)
        .then(res => {
            //console.log(res);
            if(res.status = 200) {
                this.setState({submitted: true});
            } else {
                if(res.data.errors && res.data.errors.length > 0) {
                    this.setState({submitErrors: res.errors});
                } 
            }
        }).catch( (e) => {
            console.log(e);
            this.setState({submitErrors: ['Unable to submit. Please try again later.']});
            }
        ).then(() => {
            this.setState({submitting:false});
            if(this.submitBtnRef && this.submitBtnRef.current) {
                this.submitBtnRef.current.disabled = false;
            }
        });
    }

    renderPageBodyContent = () => {
        return (
            <React.Fragment>
                <div className="mainBlock">
                    {!this.state.submitted ? 
                    <div>
                        <h1>Rental Applicant Pre-screening Form</h1>
                        <p>Thank you for your interest in our rental properties! Please take a moment to fill out our FREE, no obligation pre-screening form. If your information qualify you as a potential candidate, we will be in touch to schedule a showing. Thank you!</p>
                        <form method="post" name="rentalPrescreeningForm" id="rentalPrescreeningForm" className="forms formLeft" onSubmit={this.handleSubmit}>
                            <h2>Contact Info</h2>
                            <div className="form-group">
                                <label htmlFor="fullNameLeft">Name:</label>
                                <input type="text" className="form-control" id="fullNameLeft" name="fullNameLeft" maxLength="128" required onChange={(e) => this.state.form.fullNameLeft = e.target.value} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="phoneLeft">Phone(xxxxxxxxxx):</label>
                                <input type="text" className="form-control" id="phoneLeft" name="phoneLeft" maxLength="10" pattern="[0-9]{3}[0-9]{3}[0-9]{4}" placeholder="(xxxxxxxxxx)" required onChange={(e) => this.state.form.phoneLeft = e.target.value} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="emailLeft">Email:</label>
                                <input className="form-control" type="email" id="emailLeft" name="emailLeft" maxLength="255" pattern="\S+@\S+\.\S+" required onChange={(e) => this.state.form.emailLeft = e.target.value} />
                            </div>
                            <h2>Questions</h2>
                            <div class="form-group">
                                <label htmlFor="currentResidency">Are you renting a house or apartment right now?</label>
                                <input type="text" class="form-control" id="currentResidency" name="currentResidency" maxlength="128" required onChange={(e) => this.state.form.currentResidency = e.target.value}/>
                            </div>
                            <div class="form-group">
                                <label htmlFor="currentResidencyDuration">How long have you been living there?</label>
                                <input type="text" class="form-control" id="currentResidencyDuration" name="currentResidencyDuration" maxlength="32" required onChange={(e) => this.state.form.currentResidencyDuration = e.target.value}/>
                            </div>
                            <div class="form-group">
                                <label htmlFor="moveOutCurrentResidencyReason">Why are you moving out of your current home?</label>
                                <input type="text" class="form-control" id="moveOutCurrentResidencyReason" name="moveOutCurrentResidencyReason" maxlength="256" required onChange={(e) => this.state.form.moveOutCurrentResidencyReason = e.target.value} />
                            </div>
                            <div class="form-group">
                                <label>Have you ever been evicted?</label>
                                <div class="radio">
                                    <label><input type="radio" value="yes" name="wasEvicted" onChange={(e) => this.state.form.wasEvicted = e.target.value} required />Yes</label>
                                </div>
                                <div class="radio">
                                    <label><input type="radio" value="no" name="wasEvicted" onChange={(e) => this.state.form.wasEvicted = e.target.value} />No</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <label htmlFor="creditScore">What is your credit score?</label>
                                <input type="text" class="form-control" id="creditScore" name="creditScore" pattern="[0-9]{3}" maxlength="3" title="Please enter three digit credit score" required 
                                    onChange={(e) => this.state.form.creditScore = e.target.value} />
                            </div>
                            <div class="form-group">
                                <label htmlFor="monthlyIncome">What is your monthly income?</label>
                                <input type="text" class="form-control" id="monthlyIncome" name="monthlyIncome" required onChange={(e) => this.state.form.monthlyIncome = e.target.value} />
                            </div>
                            <div class="form-group">
                                <label htmlFor="howLongWithCurrentEmployer">How long have you been with your current employer?</label>
                                <input type="text" class="form-control" id="howLongWithCurrentEmployer" name="howLongWithCurrentEmployer" maxlength="16" required onChange={(e) => this.state.form.howLongWithCurrentEmployer = e.target.value} />
                            </div>
                            <div class="form-group">
                                <label htmlFor="readyForMoveIn">Will you be able to provide the required security deposit and first month's rent at move-in?</label>
                                <div class="radio">
                                    <label><input type="radio" value="yes" id="readyForMoveIn" name="readyForMoveIn" onChange={(e) => this.state.form.readyForMoveIn = e.target.value} required />Yes</label>
                                </div>
                                <div class="radio">
                                    <label><input type="radio" value="no" name="readyForMoveIn" onChange={(e) => this.state.form.readyForMoveIn = e.target.value} />No</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <label htmlFor="landlordEmployerHistory">Will you be able to provide references for your current landlord, previous landlord, and employer?</label>
                                <div class="radio">
                                    <label><input type="radio" value="yes" id="landlordEmployerHistory" name="landlordEmployerHistory" onChange={(e) => this.state.form.landlordEmployerHistory = e.target.value} required />Yes</label>
                                </div>
                                <div class="radio">
                                    <label><input type="radio" value="no" name="landlordEmployerHistory" onChange={(e) => this.state.form.landlordEmployerHistory = e.target.value} />No</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <label htmlFor="propertyAddr">What is the property address that you are intersted in?</label>
                                <input type="text" class="form-control" id="propertyAddr" name="propertyAddr" maxlength="256" required onChange={(e) => this.state.form.propertyAddr = e.target.value} />
                            </div>
                            <div class="form-group">
                                <label htmlFor="howManyPeople">How many peoples will be living here? And how many over 18 years old?</label>
                                <input type="text" class="form-control" id="howManyPeople" name="howManyPeople" maxlength="32" required onChange={(e) => this.state.form.howManyPeople = e.target.value} />
                            </div>
                            <div class="form-group">
                                <label htmlFor="plannedMoveInDate">When do you plan on move in? (MM/DD/YYYY)</label>
                                <input type="text" class="form-control" id="plannedMoveInDate" name="plannedMoveInDate" placeholder="MM/DD/YYYY" pattern="\d\d/\d\d/\d\d\d\d" required onChange={(e) => this.state.form.plannedMoveInDate = e.target.value} />
                            </div>
                            <div class="form-group">
                                <label htmlFor="stayForHowLong">How long are you planning to stay here?</label>
                                <input type="text" class="form-control" id="stayForHowLong" name="stayForHowLong" maxlength="64" required onChange={(e) => this.state.form.stayForHowLong = e.target.value} />
                            </div>
                            <div class="form-group">
                                <label>Does anyone in your household smoke?</label>
                                <div class="radio">
                                    <label><input type="radio" value="yes" name="doYouSmoke" onChange={(e) => this.state.form.doYouSmoke = e.target.value} required/>Yes</label>
                                </div>
                                <div class="radio">
                                    <label><input type="radio" value="no" name="doYouSmoke" onChange={(e) => this.state.form.doYouSmoke = e.target.value} />No</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <label htmlFor="doYouHavePets">Do you have any pets? Breed and Weight?</label>
                                <input type="text" class="form-control" id="doYouHavePets" name="doYouHavePets" maxlength="256" required onChange={(e) => this.state.form.doYouHavePets = e.target.value} />
                            </div>
                            <div class="form-group">
                                <label htmlFor="howDidYouHearOurRental">How did you hear about our rental?</label>
                                <input type="text" class="form-control" id="howDidYouHearOurRental" name="howDidYouHearOurRental" maxlength="128" required onChange={(e) => this.state.form.howDidYouHearOurRental = e.target.value} />
                            </div>
                            <div>
                                <button type="submit" ref={this.submitBtnRef} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent">
                                    Submit
                                </button>
                                <div id="progressBar" className={`mdl-progress mdl-js-progress mdl-progress__indeterminate ${this.state.submitting ? '' : 'hide'}`}></div>
                            </div>

                            {
                                this.state.submitErrors && this.state.submitErrors.length ?
                                <div id="errorMessageLeft">
                                    <h3>Submit failed. Errors:</h3>
                                    <ul>
                                        {this.state.submitErrors.map(e => (<li>{e}</li>))} 
                                    </ul>
                                </div>
                                :
                                <React.Fragment></React.Fragment>
                            }
                        </form>
                    </div>
                    :
                    <div>
                        Thank you! Your form has been submitted. I will get back with you ASAP.
                    </div>
                    }
                </div>
            </React.Fragment>
        );
    }

    render() {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>Rental Prescreening</title>
                    <meta name="robots" content="NOINDEX, NOFOLLOW" />
                </MetaTags>
                <PageHeader />
                <div className="container-fluid bodyWrapper">
                    <div className="row reorder-xs">
                        <div className="col-xs-12 col-md-8">
                            <PageBody renderPageBodyContent={this.renderPageBodyContent} />
                        </div>
                        <div className="col-xs-12 col-md-4">
                            <SidebarComponent hideSidebarForm={true}></SidebarComponent>
                        </div>
                    </div>
                </div>
                <PageFooter />
            </React.Fragment>
        );
    }
}

export default (RentalPrescreeningPage);