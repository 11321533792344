import React from 'react';
import MetaTags from 'react-meta-tags';

import PageHeader from './PageHeader';
import PageFooter from './PageFooter';
import PageBody from './PageBody';
import SidebarComponent from './SidebarComponent'
import {connect} from 'react-redux';
import { updateHideSidebarForm } from '../actions';
import PROD_CONTACT_API from '../apis/api';

class BuyPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                formName: 'Buy Property Form',
                bedrooms: '',
                bathrooms: '',
                priceMin: '',
                priceMax: '',
                city: '',
                state: 'TN',
                reasonForBuying: '',
                timeframeForBuying: '',
                specialInstructions: '',
                fullNameLeft: '',
                phoneLeft: '',
                emailLeft: ''
            },
            submitErrors: [],
            submitted: false,
            submitting: false
        };
    }
    submitBtnRef = React.createRef();

    handleSubmit = (e) =>
    {
        e.preventDefault();
        this.setState({submitting:true});
        this.submitBtnRef.current.disabled = false;
        //console.log('form submitted ' + JSON.stringify(this.state));
        PROD_CONTACT_API.post('/api/leads/v1', this.state.form)
        .then(res => {
            //console.log(res);
            if(res.status = 200) {
                this.setState({submitted: true});
            } else {
                if(res.data.errors && res.data.errors.length > 0) {
                    this.setState({submitErrors: res.errors});
                } 
            }
        }).catch( (e) => {
            console.log(e);
            this.setState({submitErrors: ['Unable to submit. Please try again later.']});
            }
        ).then(() => {
            this.setState({submitting:false});
            if(this.submitBtnRef && this.submitBtnRef.current) {
                this.submitBtnRef.current.disabled = false;
            }
        });
    }

    renderPageBodyContent = () => {
        return (
            <React.Fragment>
                <div className="mainBlock">
                    {!this.state.submitted ? 
                    <div>
                        <h1>Buy Your Dream Home</h1>
                        <p>Buying a new home is never easy. As a licensed <b>broker</b>, I can help you get the job done correctly, from start to closing. Please give your self a piece of mind by letting a well trained, highly skilled professional do the job for you. Please fill out the following form, let Feng take care of it for you!</p>
                        <form method="post" name="buyForm" id="buyForm" className="forms formLeft" onSubmit={this.handleSubmit}>
                            <h2>Property Details</h2>
                            <div id="propertyDetails">
                                <div className="form-group">
                                    <label htmlFor="bedrooms">Bedrooms:</label>
                                    <select className="form-control" id="bedrooms" name="bedrooms" onChange={(e) => this.state.form.bedrooms = e.target.value} required>
                                        <option value=""></option>
                                        <option value="Any">Any</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="bathrooms">Bathrooms:</label>
                                    <select className="form-control" id="bathrooms" name="bathrooms" onChange={(e) => this.state.form.bathrooms = e.target.value} required>
                                        <option value=""></option>
                                        <option value="Any">Any</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </select>
                                </div>
                                <div className="form-inline">
                                    <label htmlFor="priceMin">Price range:</label>
                                    <select className="form-control" id="priceMin" name="priceMin" onChange={(e) => this.state.form.priceMin = e.target.value} required>
                                        <option value=""></option>
                                        <option>$50,000</option>
                                        <option>$100,000</option>
                                        <option>$150,000</option>
                                        <option>$200,000</option>
                                        <option>$250,000</option>
                                        <option>$300,000</option>
                                        <option>$350,000</option>
                                        <option>$400,000</option>
                                        <option>$500,000</option>
                                        <option>$600,000+</option>
                                    </select> -
                                    <select className="form-control" id="priceMax" name="priceMax" onChange={(e) => this.state.form.priceMax = e.target.value} required>
                                        <option value=""></option>
                                        <option>$50,000</option>
                                        <option>$100,000</option>
                                        <option>$150,000</option>
                                        <option>$200,000</option>
                                        <option>$250,000</option>
                                        <option>$300,000</option>
                                        <option>$400,000</option>
                                        <option>$500,000</option>
                                        <option>$900,000+</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="city">City:</label>
                                    <select className="form-control" id="city" name="city" onChange={(e) => this.state.form.city = e.target.value} required>
                                        <option value=""></option>
                                        <option>Arlington</option>
                                        <option>Bartlett</option>
                                        <option>Collierville</option>
                                        <option>Cordova</option>
                                        <option>Germantown</option>
                                        <option>Lakeland</option>
                                        <option>Memphis</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>State:</label><span>&nbsp;TN</span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="reasonForBuying">Reason for Buying:</label>
                                    <input type="text" className="form-control" id="reasonForBuying" name="reasonForBuying" maxLength="1024" required 
                                        onChange={(e) => this.state.form.reasonForBuying = e.target.value} />
                                </div>
                                <div className="form-group">
                                    <label>Within how many months do you plan to buy a home</label>
                                    <div>
                                        <div className="radio">
                                            <label><input type="radio" value="0-1 month" name="timeframeForBuying" onChange={(e) => this.state.form.timeframeForBuying = e.target.value} required />0-1 month</label>
                                        </div>
                                        <div className="radio">
                                            <label><input type="radio" value="1-3 months" name="timeframeForBuying" onChange={(e) => this.state.form.timeframeForBuying = e.target.value} />1-3 months</label>
                                        </div>
                                        <div className="radio">
                                            <label><input type="radio" value="3-6 months" name="timeframeForBuying" onChange={(e) => this.state.form.timeframeForBuying = e.target.value} />3-6 months</label>
                                        </div>
                                        <div className="radio">
                                            <label><input type="radio" value="6-12 months" name="timeframeForBuying" onChange={(e) => this.state.form.timeframeForBuying = e.target.value} />6-12 months</label>
                                        </div>
                                        <div className="radio">
                                            <label><input type="radio" value="12+ months" name="timeframeForBuying" onChange={(e) => this.state.form.timeframeForBuying = e.target.value} />12+ months</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="specialInstructions">Special Instructions (optional):</label>
                                    <textarea className="form-control" rows="5" id="specialInstructions" name="specialInstructions" maxLength="1024" onChange={(e) => this.state.form.specialInstructions = e.target.value}></textarea>
                                </div>
                            </div>
                            <h2>Contact Info</h2>
                            <div className="form-group">
                                <label htmlFor="fullNameLeft">Name:</label>
                                <input type="text" className="form-control" id="fullNameLeft" name="fullNameLeft" maxLength="128" required onChange={(e) => this.state.form.fullNameLeft = e.target.value} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="phoneLeft">Phone(xxxxxxxxxx):</label>
                                <input type="text" className="form-control" id="phoneLeft" name="phoneLeft" maxLength="10" pattern="[0-9]{3}[0-9]{3}[0-9]{4}" placeholder="(xxxxxxxxxx)" 
                                    required onChange={(e) => this.state.form.phoneLeft = e.target.value} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="emailLeft">Email:</label>
                                <input className="form-control" type="email" id="emailLeft" name="emailLeft" maxLength="255" pattern="\S+@\S+\.\S+" 
                                    required onChange={(e) => this.state.form.emailLeft = e.target.value} />
                            </div>
                            <div>
                                <button type="submit" ref={this.submitBtnRef} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent">
                                    Submit
                                </button>
                                <div id="progressBar" className={`mdl-progress mdl-js-progress mdl-progress__indeterminate ${this.state.submitting ? '' : 'hide'}`}></div>
                            </div>

                            {
                                this.state.submitErrors && this.state.submitErrors.length ?
                                <div id="errorMessageLeft">
                                    <h3>Submit failed. Errors:</h3>
                                    <ul>
                                        {this.state.submitErrors.map(e => (<li>{e}</li>))} 
                                    </ul>
                                </div>
                                :
                                <React.Fragment></React.Fragment>
                            }
                        </form>
                    </div>
                    :
                    <div>
                        Thank you! Your form has been submitted. I will get back with you ASAP.
                    </div>
                    }
                </div>
            </React.Fragment>
        );
    }

    render() {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>Buy Your Dream Home</title>
                    <meta name="description" content="Buying your dream home in the Memphis area" />
                    <link rel="cannonical" href="/buy" />
                </MetaTags>
                <PageHeader />
                <div className="container-fluid bodyWrapper">
                    <div className="row reorder-xs">
                        <div className="col-xs-12 col-md-8">
                            <PageBody renderPageBodyContent={this.renderPageBodyContent} />
                        </div>
                        <div className="col-xs-12 col-md-4">
                            <SidebarComponent hideSidebarForm={true}></SidebarComponent>
                        </div>
                    </div>
                </div>
                <PageFooter />
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        //hideSidebarForm: state.hideSidebarForm,
    };
}
export default connect(mapStateToProps, {updateHideSidebarForm})(BuyPage);