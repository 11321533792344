import React from 'react';
import { NavLink} from "react-router-dom";

class PageHeader extends React.Component {
    constructor(props) {
        super(props);
        this.menuPanelRef = React.createRef();
        this.hamburgerRef = React.createRef();
    }
    componentDidMount() {
        window.addEventListener('resize', this.hideMenuPanel)
    }
      
    componentWillUnmount() {
        window.removeEventListener('resize', this.hideMenuPanel)
    }
    hideMenuPanel = () =>  {
        if(window.innerWidth >= 768) {
            if( this.menuPanelRef.current.style.display == 'none') {
                this.menuPanelRef.current.style.display = "block";
                this.hamburgerRef.current.classList.remove("is-active");
            }
        } else {
            this.menuPanelRef.current.style.display = "none";
            this.hamburgerRef.current.classList.remove("is-active");
        }
    }
    toggleMobileMenu = () => {
        this.hamburgerRef.current.classList.toggle("is-active");
        if(this.hamburgerRef.current.className.indexOf("is-active")>0) {
            this.menuPanelRef.current.style.display = "block";
        } else {
            this.menuPanelRef.current.style.display = "none";
        }
    }

    render() {
        return (
            <React.Fragment>
                <header className="siteHeader" id="siteHeaderAnchor">
                    <div className="headerTop">
                        <span>Feng Cai</span>
                        <span>Realtor</span>
                        <span>Broker</span>
                        <span>Licensed in TN</span>
                        <span><a href="tel:9012684273">901-268-4273</a></span>
                    </div>
                    <div className="mainHeader">
                        <NavLink className="nav-link" exact to="/"><img src="/img/feng/fengcai.jpg" className="siteLogo"/></NavLink>
                        <button className="hamburger hamburger--collapse" type="button" onClick={this.toggleMobileMenu} ref={this.hamburgerRef}>
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                        </button>
                    </div>
                    <div className="menuPanel" ref={this.menuPanelRef}>
                        <ul>
                            <li>
                                <NavLink className="nav-link" exact to="/" activeClassName="menu-is-active">Home</NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" exact to="/buy" activeClassName="menu-is-active" onClick={this.hideMenuPanel}>Buy</NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" exact to="/sell" activeClassName="menu-is-active" onClick={this.hideMenuPanel}>Sell</NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" exact to="/business-card" activeClassName="menu-is-active" onClick={this.hideMenuPanel}>Business Card</NavLink>
                            </li>
                        </ul>
                    </div>
                </header>
                <div id="scroller-anchor"></div>
            </React.Fragment>
        );
    }
}

export default PageHeader;