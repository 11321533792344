import React from 'react';
import MetaTags from 'react-meta-tags';

import PageHeader from './PageHeader';
import PageFooter from './PageFooter';
import PageBody from './PageBody';
import SidebarComponent from './SidebarComponent'
import PROD_CONTACT_API from '../apis/api';

class SellPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                formName: 'Sell Property Form',
                propertyAddress: '',
                askingPrice: '',
                lowestAcceptablePrice: '',
                reasonForSelling: '',
                timeframeForSelling: '',
                specialInstructions: '',
                fullNameLeft: '',
                phoneLeft: '',
                emailLeft: ''
            },
            submitErrors: [],
            submitted: false,
            submitting: false
        };
    }
    submitBtnRef = React.createRef();

    handleSubmit = (e) =>
    {
        e.preventDefault();
        this.setState({submitting:true});
        this.submitBtnRef.current.disabled = true;
        //console.log('form submitted ' + JSON.stringify(this.state));
        PROD_CONTACT_API.post('/api/leads/v1', this.state.form)
        .then(res => {
            //console.log(res);
            if(res.status = 200) {
                this.setState({submitted: true});
            } else {
                if(res.data.errors && res.data.errors.length > 0) {
                    this.setState({submitErrors: res.errors});
                } 
            }
        }).catch( (e) => {
            console.log(e);
            this.setState({submitErrors: ['Unable to submit. Please try again later.']});
            }
        ).then(() => { 
            this.setState({submitting:false});
            if(this.submitBtnRef && this.submitBtnRef.current) {
                this.submitBtnRef.current.disabled = false;
            }
        });
    }

    renderPageBodyContent = () => {
        return (
            <React.Fragment>
                <div className="mainBlock">
                    {!this.state.submitted ? 
                    <div>
                        <h1>Sell Your House</h1>
                        <p>Moving is a pain already. Don't make it harder. Let a professional real estate agent take care of selling your home. Please fill out the following form, leave all the hustle to Feng!</p>
                        <form method="post" name="sellForm" id="sellForm" className="forms formLeft" onSubmit={this.handleSubmit}>
                            <h2>Property Details</h2>
                            <div id="propertyDetails">
                                <div className="form-group">
                                    <label htmlFor="propertyAddress">Property Address:</label>
                                    <input type="text" className="form-control" id="propertyAddress" name="propertyAddress" maxlength="256" placeholder="Full address" required 
                                         onChange={(e) =>  this.state.form.propertyAddress = e.target.value} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="askingPrice">Asking Price:</label>
                                    <input type="text" className="form-control" id="askingPrice" name="askingPrice" maxlength="30" required 
                                        onChange={(e) =>  this.state.form.askingPrice = e.target.value} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="lowestAcceptablePrice">Lowest Acceptable Price:</label>
                                    <input type="text" className="form-control" id="lowestAcceptablePrice" name="lowestAcceptablePrice" maxlength="30" required 
                                        onChange={(e) =>  this.state.form.lowestAcceptablePrice = e.target.value} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="reasonForSelling">Reason for Selling:</label>
                                    <input type="text" className="form-control" id="reasonForSelling" name="reasonForSelling" maxLength="1024" required 
                                        onChange={(e) =>  this.state.form.reasonForSelling = e.target.value} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="timeframeForSelling">Timeframe for Selling (MM/DD/YYYY):</label>
                                    <input type="text" className="form-control" id="timeframeForSelling" name="timeframeForSelling" placeholder="MM/DD/YYYY" pattern="\d\d/\d\d/\d\d\d\d" required 
                                        onChange={(e) =>  this.state.form.timeframeForSelling = e.target.value} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="specialInstructions">Special Instructions (optional):</label>
                                    <textarea className="form-control" rows="5" id="specialInstructions" name="specialInstructions" maxLength="1024" onChange={(e) =>  this.state.form.specialInstructions = e.target.value}></textarea>
                                </div>
                            </div>
                            <h2>Contact Info</h2>
                            <div className="form-group">
                                <label htmlFor="fullNameLeft">Name:</label>
                                <input type="text" className="form-control" id="fullNameLeft" name="fullNameLeft" maxLength="128" required onChange={(e) =>  this.state.form.fullNameLeft = e.target.value} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="phoneLeft">Phone(xxxxxxxxxx):</label>
                                <input type="text" className="form-control" id="phoneLeft" name="phoneLeft" maxLength="10" pattern="[0-9]{3}[0-9]{3}[0-9]{4}" placeholder="(xxxxxxxxxx)" required onChange={(e) =>  this.state.form.phoneLeft = e.target.value} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="emailLeft">Email:</label>
                                <input className="form-control" type="email" id="emailLeft" name="emailLeft" maxLength="255" pattern="\S+@\S+\.\S+" required onChange={(e) =>  this.state.form.emailLeft = e.target.value} />
                            </div>
                            <div>
                                <button type="submit" ref={this.submitBtnRef} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent">
                                    Submit
                                </button>
                                <div id="progressBar" className={`mdl-progress mdl-js-progress mdl-progress__indeterminate ${this.state.submitting ? '' : 'hide'}`}></div>
                            </div>
                            {
                                this.state.submitErrors && this.state.submitErrors.length ?
                                <div id="errorMessageLeft">
                                    <h3>Submit failed. Errors:</h3>
                                    <ul>
                                        {this.state.submitErrors.map(e => (<li>{e}</li>))} 
                                    </ul>
                                </div>
                                :
                                <React.Fragment></React.Fragment>
                            }
                        </form>
                    </div>
                    :
                    <div>
                        Thank you! Your form has been submitted. I will get back with you ASAP.
                    </div>
                    }
                </div>
            </React.Fragment>
        );
    }

    render() {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>Sell Your House</title>
                    <meta name="description" content="Selling houses in the Memphis area" />
                    <link rel="cannonical" href="/sell" />
                </MetaTags>
                <PageHeader />
                <div className="container-fluid bodyWrapper">
                    <div className="row reorder-xs">
                        <div className="col-xs-12 col-md-8">
                            <PageBody renderPageBodyContent={this.renderPageBodyContent} />
                        </div>
                        <div className="col-xs-12 col-md-4">
                            <SidebarComponent hideSidebarForm={true}></SidebarComponent>
                        </div>
                    </div>
                </div>
                <PageFooter />
            </React.Fragment>
        );
    }
}

export default SellPage;