import React from 'react';

class OfficeMapContent extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div></div>
            </React.Fragment>
        );
    }
}

export default OfficeMapContent;